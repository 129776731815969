.container {
  display: flex;
  height: 100vh;
}

.content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: var(--color-text);
}
