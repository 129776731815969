.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.container {
  padding: var(--container-padding);
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

/*.horizontal_padding {*/
/*  padding: 0 var(--container-padding);*/
/*}*/

/*.vertical_padding {*/
/*  padding: var(--container-padding) 0;*/
/*}*/

.form {
  display: flex;
  width: 360px;
  flex-direction: column;
  gap: 16px;
}

.heading {
  font-size: 24px;
  color: white;
  margin-bottom: 16px;
}
