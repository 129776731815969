.searchbar {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-content: center;
  background-color: #1F1F1F;
  align-items: center;
  padding: var(--container-padding);
  border-bottom: 1px solid var(--color-background);
}

.searchbar > .inputContainer {
  flex-grow: 1;
}

.container {
  display: flex;
  height: 100vh;
}

.list {
  background-color: #1F1F1F;
  flex-basis: 280px;
  min-width: 280px;
  flex-grow: 1;
  border-right: 1px solid var(--color-background);
}
