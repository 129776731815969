.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--color-primary);
  flex-shrink: 0;
  display: flex;
}

.initials {
  color: var( --color-text);
  font-size: 16px;
  line-height: 20px;
  margin: auto;
}
